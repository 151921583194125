/* eslint-disable */
const ls = window.localStorage;
const lsPrefix = "zy_";

export const utilsCCPC={
  /** 获取用户token */
  getAccessToken (data={}) {
    try {
      return jsToNative("getAccessToken",JSON.stringify(data));
    }catch (e){
      console.log(e)
      return "b9a23702-f9c5-4b63-813e-6116889e6347"
      return false
    }
  },

};

export function utilsgetDictValue(dict=[],code){
  let value = ""
  dict.forEach((item)=>{
    if(item.code == code) value = item.value
  })
  return value
}

export function utilsDurationConversion (time) {
  const h = this.utilsCCPC.strInit(Math.trunc(time / 60 / 60))
  const m = this.utilsCCPC.strInit(Math.trunc(time / 60 % 60))
  const s = this.utilsCCPC.strInit(Math.trunc(time % 60))
  const duration = h + ':' + m + ':' + s
  return duration
}
