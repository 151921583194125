import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index'
import Search from '../views/search/index'
import Details from '../views/details/index'
// import tDetails from '../views/details/tindex'
import Abnormal404 from '../views/abnormal/404'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/details',
    name: 'details',
    component: Details
  },
  {
    path: '/tdetails',
    name: 'tdetails',
    component: Details
    // component: tDetails
  },
  {
    path: '/404',
    name: 'abnormal404',
    component: Abnormal404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
