<template>
  <div class="css-wap wap-common-head">
    <img class="wap-common-head-rollback" v-if="rollbackShow" src="../../static/img/wap/wap-common-head-rollback.png" @click="openRollback">
    <input class="wap-common-head-value" type="text" placeholder="游戏搜索，搜你想搜" v-model="value">
    <div class="wap-common-head-search" @click="openSearch">搜索</div>
  </div>
</template>

<script>
export default {
  name: 'wapCommonHead',
  props: {
    rollbackShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: undefined
    }
  },
  created () {
    this.value = this.$route.query.value
  },
  methods: {
    openRollback () {
      this.$emit('rollback')
    },
    openSearch () {
      this.$emit('search', this.value)
      if (this.value !== this.$route.query.value) {
        if (this.value && this.value !== '') {
          this.$router.push({
            path: '/search',
            query: {
              value: this.value
            }
          })
        } else {
          this.$router.push({
            path: '/search'
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wap-common-head{
  position: sticky;
  top: 0;
  height: 2.75rem;
  background: #488BFF;
  display: flex;
  align-items: center;
  padding-left: 0.9375rem;
  z-index:999;
  .wap-common-head-rollback{
    flex-shrink: 0;
    width: 0.5625rem;
    height: 1.0625rem;
    margin-right: 0.9375rem;
  }
  .wap-common-head-value{
    box-sizing: border-box;
    width: 100%;
    height: 2.0625rem;
    background: #FFFFFF;
    border-radius: 1.0625rem;
    padding: 0 0.9375rem;
    margin: 0;
    border: none;
  }
  .wap-common-head-search{
    flex-shrink: 0;
    font-size: 1rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0 0.9375rem;
  }
}
</style>
