<template>
  <div class="css-pc common-head">
    <div class="common-head-main">
      <img class="common-head-main-logo" src="../../static/img/www/logo.png" @click="openHome">
      <div class="common-head-main-search">
        <img class="home-search-main-search-icon" src="../../static/img/www/home-search-main-icon.png">
        <input class="home-search-main-search-value" type="" placeholder="游戏搜索，搜你想搜" v-model="value">
        <div class="home-search-main-search-btn" @click="openSearch">搜索</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonHead',
  data () {
    return {
      value: undefined
    }
  },
  created () {
    this.value = this.$route.query.value
  },
  methods: {
    openHome () {
      this.$router.push({
        path: '/'
      })
    },
    openSearch () {
      this.$emit('search', this.value)
      if (this.value !== this.$route.query.value) {
        if (this.value && this.value !== '') {
          this.$router.push({
            path: '/search',
            query: {
              value: this.value
            }
          })
        } else {
          this.$router.push({
            path: '/search'
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.common-head{
  height: 77px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  .common-head-main{
    position: relative;
    width: 1400px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .common-head-main-logo{
      position: absolute;
      left: 50px;
      width: 56px;
      height: 38px;
    }
    .common-head-main-search{
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 582px;
      height: 46px;
      background: #FFFFFF;
      border: 1px solid #7F88FF;
      border-radius: 23px;
      .home-search-main-search-icon{
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        margin: 0 18px;
      }
      .home-search-main-search-value{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        margin-right: 18px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
      .home-search-main-search-btn{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 101px;
        height: 100%;
        background: #488BFF;
        border-radius: 23px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>
