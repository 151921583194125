<template>
  <div class="home" @scroll="rankingConfListScroll">
    <!--logo-->
    <div class="css-pc home-logo">
      <img src="../static/img/www/logo.png">
    </div>
    <!--搜索-->
    <div class="css-pc home-search">
      <div class="home-search-main">
        <img class="home-search-main-icon" src="../static/img/www/home-search-main-icon.png">
        <input class="home-search-main-value" type="" placeholder="游戏搜索，搜你想搜" v-model="value">
        <div class="home-search-main-btn" @click="openSearch">搜索</div>
      </div>
    </div>
    <!--排行-->
    <div class="css-pc home-ranking">
      <div class="home-ranking-area">
        <div class="home-ranking-area-head">
          新游榜
          <div class="home-ranking-area-head-sign"></div>
        </div>
        <div class="home-ranking-area-body">
          <div class="home-ranking-area-body-item"
               v-for="(item,index) in listNewRankPC" :key="index"
               @click="openDetails(item)"
          >
            <img class="home-ranking-area-body-item-icon" :src="item.staticIconUrl">
            <div class="home-ranking-area-body-item-name">{{item.appName}}</div>
            <div class="home-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
          </div>
        </div>
        <div class="home-ranking-area-pagination">
          <van-pagination v-model="newRankParameter.current"
                          :total-items="listNewRankConf.total"
                          :show-page-size="10"
                          :items-per-page="newRankParameter.size"
                          @change="getNewRankDataPC()"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </div>
      <div class="home-ranking-area">
        <div class="home-ranking-area-head">
          热门榜
          <div class="home-ranking-area-head-sign"></div>
        </div>
        <div class="home-ranking-area-body">
          <div class="home-ranking-area-body-item"
               v-for="(item,index) in listHotRankPC" :key="index"
               @click="openDetails(item)"
          >
            <img class="home-ranking-area-body-item-icon" :src="item.staticIconUrl">
            <div class="home-ranking-area-body-item-name">{{item.appName}}</div>
            <div class="home-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
          </div>
        </div>
        <div class="home-ranking-area-pagination">
          <van-pagination v-model="hotRankParameter.current"
                          :total-items="listHotRankConf.total"
                          :show-page-size="10"
                          :items-per-page="hotRankParameter.size"
                          @change="getHotRankDataPC()"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>

      </div>
    </div>

    <div class="css-wap wap-common-vessel">
      <wapCommonHead :rollbackShow="false" @search="wapCommonHeadSearch"></wapCommonHead>
      <!--logo-->
      <div class="home-logo">
        <img src="../static/img/wap/logo.png">
      </div>
      <!--排行-->
      <div class="home-ranking">
        <div class="home-ranking-area">
          <div class="home-ranking-area-head">
            <div class="home-ranking-area-head-dot"></div>
            <div class="home-ranking-area-head-name">新游榜</div>
            <div class="home-ranking-area-head-dot"></div>
          </div>
          <div class="home-ranking-area-body">
            <div class="home-ranking-area-body-item"
                 v-for="(item,index) in listNewRank" :key="index"
                 @click="openDetails(item)"
            >
              <template v-if="index<=2">
                <img v-if="index==0" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_1.png">
                <img v-if="index==1" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_2.png">
                <img v-if="index==2" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_3.png">
              </template>
              <template v-else>
                <div class="home-ranking-area-body-item-ranking">{{index+1}}</div>
              </template>
              <img class="home-ranking-area-body-item-icon" :src="item.staticIconUrl">
              <div class="home-ranking-area-body-item-content">
                <div class="home-ranking-area-body-item-name">{{item.appName}}</div>
                <div class="home-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-ranking-area">
          <div class="home-ranking-area-head">
            <div class="home-ranking-area-head-dot"></div>
            <div class="home-ranking-area-head-name">热门榜</div>
            <div class="home-ranking-area-head-dot"></div>
          </div>
          <div class="home-ranking-area-body">
            <div class="home-ranking-area-body-item"
                 v-for="(item,index) in listHotRank" :key="index"
                 @click="openDetails(item)"
            >
              <template v-if="index<=2">
                <img v-if="index==0" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_1.png">
                <img v-if="index==1" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_2.png">
                <img v-if="index==2" class="home-ranking-area-body-item-ranking" src="../static/img/wap/ranking_3.png">
              </template>
              <template v-else>
                <div class="home-ranking-area-body-item-ranking">{{index+1}}</div>
              </template>
              <img class="home-ranking-area-body-item-icon" :src="item.staticIconUrl">
              <div class="home-ranking-area-body-item-content">
                <div class="home-ranking-area-body-item-name">{{item.appName}}</div>
                <div class="home-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import overallData from '../../public/js/overall'
import wapCommonHead from './common/wapCommonHead'
export default {
  name: 'index',
  data () {
    return {
      listNewRankPC: [],
      newRankParameter: {
        current: 1,
        size: 10
      },

      listHotRankPC: [],
      hotRankParameter: {
        current: 1,
        size: 10
      },

      listNewRank: [],
      listNewRankState: false,
      listNewRankConf: {
        current: 1,
        size: 10
      },
      listHotRank: [],
      listHotRankState: false,
      listHotRankConf: {
        current: 1,
        size: 10
      },
      value: undefined
    }
  },
  components: {
    wapCommonHead
  },
  created () {
    this.getNewRankData()
    this.getHotRankData()
    this.getNewRankDataPC()
    this.getHotRankDataPC()
  },
  methods: {
    rankingConfListScroll (e) {
      const scrollTop = e.target.scrollTop // 滚动条当前滚动的距离
      const clientHeight = e.target.clientHeight // 可视区域高度
      const scrollHeight = e.target.scrollHeight // 滚动条整体高度
      const bottomHeight = scrollHeight - scrollTop - clientHeight
      if (bottomHeight <= 100 && this.listNewRankState !== true && this.listNewRankConf && this.listNewRank && this.listNewRank.length < this.listNewRankConf.total) {
        this.getNewRankNext()
      }
      if (bottomHeight <= 100 && this.listHotRankState !== true && this.listHotRankConf && this.listHotRank && this.listHotRank.length < this.listHotRankConf.total) {
        this.getHotRankNext()
      }
    },
    getNewRankNext () {
      this.listNewRankConf.current = this.listNewRankConf.current + 1
      this.getNewRankData()
    },
    getHotRankNext () {
      this.listHotRankConf.current = this.listHotRankConf.current + 1
      this.getHotRankData()
    },
    wapCommonHeadSearch (value) {
      if (!value) {
        this.$router.push({
          path: '/search'
        })
      }
    },
    openSearch () {
      this.$emit('search', this.value)
      if (this.value && this.value !== '') {
        this.$router.push({
          path: '/search',
          query: {
            value: this.value
          }
        })
      } else {
        this.$router.push({
          path: '/search'
        })
      }
    },
    openDetails (item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    openDownload (item) {
      window.location.href = item.staticApkUrl
    },
    /** 获取数据 */
    getNewRankDataPC () {
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/listNewRank4V6', {
        current: this.newRankParameter.current,
        size: this.newRankParameter.size
      })
        .then((response) => {
          if (response.code === 0) {
            this.listNewRankPC = response.data.records ? response.data.records : []
          }
        })
        .catch(function (error) {
          console.log('catch', error)
        })
    },
    getNewRankData () {
      this.listNewRankState = true
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/listNewRank4V6', {
        current: this.listNewRankConf.current,
        size: this.listNewRankConf.size
      })
        .then((response) => {
          this.listNewRankState = false
          if (response.code === 0) {
            this.listNewRankConf = response.data
            const records = response.data.records ? response.data.records : []
            records.forEach((item) => {
              this.listNewRank.push(item)
            })
          }
        })
        .catch(function (error) {
          this.listNewRankState = false
          console.log('catch', error)
        })
    },
    /** 获取数据 */
    getHotRankDataPC () {
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/listHotRank4V6', {
        current: this.hotRankParameter.current,
        size: this.hotRankParameter.size
      })
        .then((response) => {
          if (response.code === 0) {
            this.listHotRankPC = response.data.records ? response.data.records : []
          }
        })
        .catch(function (error) {
          console.log('catch', error)
        })
    },
    getHotRankData () {
      this.listHotRankState = true
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/listHotRank4V6', {
        current: this.listHotRankConf.current,
        size: this.listHotRankConf.size
      })
        .then((response) => {
          this.listHotRankState = false
          if (response.code === 0) {
            this.listHotRankConf = response.data
            const records = response.data.records ? response.data.records : []
            records.forEach((item) => {
              this.listHotRank.push(item)
            })
          }
        })
        .catch(function (error) {
          this.listHotRankState = false
          console.log('catch', error)
        })
    }
  }
}
</script>

<style>

html{
  background: #F5F7FA;

}

</style>
<style scoped lang="scss">

/*www*/
@media screen and (min-width: 600px) {
  .home{
    padding-bottom: 60px;
    .home-logo{
      display: flex;
      justify-content: center;
      margin-top: 112px;
      >img{
        width: 167px;
        height: 113px;
      }
    }
    .home-search{
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .home-search-main{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 874px;
        height: 60px;
        padding-left: 24px;
        background: #FFFFFF;
        border-radius: 30px;
        .home-search-main-icon{
          width: 28px;
          height: 28px;
          flex-shrink: 0;
        }
        .home-search-main-value{
          box-sizing: border-box;
          padding: 0 15px;
          margin: 0;
          border: none;
          background: none;
          height: 100%;
          width: 100%;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        .home-search-main-btn{
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 131px;
          height: 60px;
          background: #488BFF;
          border-radius: 30px;
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          flex-shrink: 0;
        }
      }

    }
    .home-ranking{
      display: flex;
      justify-content: center;
      .home-ranking-area{
        position: relative;
        flex-shrink: 0;
        width: 690px;
        margin: 0 5px;
        margin-top: 70px;
        background: #FFFFFF;
        .home-ranking-area-head{
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 30px;
          height: 67px;
          border-bottom: 1px solid #EBEDF0;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          .home-ranking-area-head-sign{
            position: absolute;
            left: 30px;
            bottom: 0px;
            width: 42px;
            height: 4px;
            background: #488BFF;
          }
        }
        .home-ranking-area-body{
          min-height: 460px;
          display: flex;
          flex-wrap: wrap;
          .home-ranking-area-body-item{
            cursor: pointer;
            padding: 0px 20px;
            box-sizing: border-box;
            width: 50%;
            height: 92px;
            display: flex;
            align-items: center;
            .home-ranking-area-body-item-icon{
              flex-shrink: 0;
              width: 72px;
              height: 72px;
              border-radius: 18px;
            }
            .home-ranking-area-body-item-name{
              box-sizing: border-box;
              padding: 0 10px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .home-ranking-area-body-item-btn{
              cursor: pointer;
              display: none;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 61px;
              height: 30px;
              background: #488BFF;
              border-radius: 15px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .home-ranking-area-body-item:hover{
            background: #F5F9FF;
            .home-ranking-area-body-item-btn{
              display: flex;
            }
          }
        }
        .home-ranking-area-pagination{
          margin-top: 10px;
        }
      }

    }
  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .home{
    height: 100vh;
    overflow: auto;
    .home-logo{
      display: flex;
      justify-content: center;
      padding-top: 1.125rem;
      >img{
        width: 5rem;
        height: 3.75rem;
      }
    }
    .home-ranking{
      display: flex;
      justify-content: center;
      padding-bottom: 1.25rem;
      .home-ranking-area{
        box-sizing: border-box;
        flex-shrink: 0;
        width: 10.625rem;
        background: #FFFFFF;
        border-radius: 1rem;
        margin: 0 0.25rem;
        margin-top: 0.9375rem;
        .home-ranking-area-head{
          padding: 0.75rem 0;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .home-ranking-area-head-dot{
            width: 0.25rem;
            height: 0.25rem;
            background: #333333;
            border-radius: 50%;
          }
          .home-ranking-area-head-name{
            padding: 0 0.9375rem;
            font-size: 1.0625rem;
            font-family: Alimama ShuHeiTi;
            font-weight: bold;
            color: #333333;
          }
        }
        .home-ranking-area-body{
          .home-ranking-area-body-item{
            display: flex;
            align-items: center;
            padding: 0.625rem 0.5rem;
            .home-ranking-area-body-item-ranking{
              flex-shrink: 0;
              width: 1rem;
              height: 1.125rem;
              font-size: 0.6875rem;
              font-family: Source Han Sans CN;
              font-weight: 600;
              font-style: italic;
              color: #CACACA;
            }
            .home-ranking-area-body-item-icon{
              flex-shrink: 0;
              margin: 0 0.5625rem;
              width: 3rem;
              height: 3rem;
            }
            .home-ranking-area-body-item-content{
              .home-ranking-area-body-item-name{
                font-size: 0.6875rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .home-ranking-area-body-item-btn{
                margin-top: 0.375rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.1875rem;
                height: 1.125rem;
                background: #488BFF;
                border-radius: 0.5625rem;
                font-size: 0.5625rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
              }
            }

          }
        }
      }

    }
  }
}
</style>
