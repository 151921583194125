import axios from 'axios'
// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      /* 设置请求头部 */
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    if (config.method === 'post' || config.method === 'put') {
      /* 设置请求头部 */
      config.headers = {
        'Content-Type': 'application/json'
      }
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

// 添加响应拦截器<==>响应回来后做的事
axios.interceptors.response.use((response) => {
  setTimeout(() => {
  }, 200)
  const responseData = response.data
  // console.log('响应拦截器', responseData)
  return responseData
}, function (err) {
  console.log('响应拦截器', err)
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '请求错误'
        break

      case 401:
        err.message = '未授权，请登录'
        break

      case 403:
        err.message = '拒绝访问'
        break

      case 404:
        err.message = `请求地址出错: ${err.response.config.url}`
        break

      case 408:
        err.message = '请求超时'
        break

      case 500:
        err.message = '服务器内部错误'
        break

      case 501:
        err.message = '服务未实现'
        break

      case 502:
        err.message = '网关错误'
        break

      case 503:
        err.message = '服务不可用'
        break

      case 504:
        err.message = '网关超时'
        break

      case 505:
        err.message = 'HTTP版本不受支持'
        break

      default:
    }
  }
  return Promise.reject(err)
})
