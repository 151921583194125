<template>
  <div class="commonSearchType">
    <div class="commonSearchType-main">
      <div class="commonSearchType-main-item"
           :class="type=='1'?'active':''"
           @click.stop="pickType('1')">
        游戏
      </div>
      <div class="commonSearchType-main-item"
           :class="type=='25'?'active':''"
           @click.stop="pickType('25')">
        软件
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonSearchType',
  props: {
    type: {
      type: [String, Number]
    }
  },
  data () {
    return {

    }
  },
  methods: {
    pickType (type) {
      if (type !== this.type) { this.$emit('change', type) }
    }
  }
}
</script>

<style scoped lang="scss">
/*www*/
@media screen and (min-width: 600px){
  .commonSearchType{
    padding: 0 14px;
    background: #ffffff;
    .commonSearchType-main{
      display: flex;
      align-content: center;
      height: 38px;
      width: 400px;
      background: #F0F3F7;
      border-radius: 18px;
      padding: 2px;
      .commonSearchType-main-item{
        cursor: pointer;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
      .commonSearchType-main-item.active{
        background: #FFFFFF;
        border-radius: 17px;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .commonSearchType{
    padding: 0 0.875rem;
    padding-top: 0.9375rem;
    background: #ffffff;
    .commonSearchType-main{
      display: flex;
      align-content: center;
      height: 2.375rem;
      background: #F0F3F7;
      border-radius: 1.125rem;
      padding: 0.125rem;
      .commonSearchType-main-item{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8125rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
      .commonSearchType-main-item.active{
        background: #FFFFFF;
        border-radius: 1.0625rem;
        font-size: 0.8125rem;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}
</style>
