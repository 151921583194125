<template>
  <div class="search" @scroll="searchConfListScroll">
    <commonHead @search="commonHeadSearch"></commonHead>
    <wapCommonHead @search="commonHeadSearch" @rollback="rollbackCommon"></wapCommonHead>
    <commonSearchType class="css-wap" :type="parameterWap.categoryId" @change="commonSearchTypeChange"></commonSearchType>
    <div class="search-ranking">
      <div class="search-ranking-area">
        <div class="search-ranking-area-head">
          <template v-if="searchConf">
            共 <span>{{searchConf.realTotal}}</span> 条搜索结果
          </template>
          <div class="search-ranking-area-head-type css-pc">
            <commonSearchType :type="parameter.categoryId" @change="commonSearchTypeChange"></commonSearchType>
          </div>
        </div>
        <div class="css-pc search-ranking-area-body" v-if="searchConf && searchList && searchList.length>0">
          <div class="search-ranking-area-body-item"
               v-for="(item,index) in searchList" :key="index"
               @click="openDetails(item)"
          >
            <img class="search-ranking-area-body-item-icon" :src="item.staticIconUrl">
            <div class="css-pc search-ranking-area-body-item-name">{{item.appName}}</div>
            <div class="css-wap search-ranking-area-body-item-detail">
              <div class="search-ranking-area-body-item-name font-line-1">{{item.appName}}</div>
              <div class="search-ranking-area-body-item-type font-line-1">{{item.standardCategoryName}}  /  V{{item.versionName}}</div>
              <div class="search-ranking-area-body-item-describe font-line-1">这是一个极具挑战性和复杂的应用这是一个极具挑战性和复杂的应用</div>
            </div>
            <div class="search-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
          </div>
        </div>
        <div class="css-wap search-ranking-area-body" v-if="searchConf && searchListWap && searchListWap.length>0">
          <div class="search-ranking-area-body-item"
               v-for="(item,index) in searchListWap" :key="index"
               @click="openDetails(item)"
          >
            <img class="search-ranking-area-body-item-icon" :src="item.staticIconUrl">
            <div class="css-pc search-ranking-area-body-item-name">{{item.appName}}</div>
            <div class="css-wap search-ranking-area-body-item-detail">
              <div class="search-ranking-area-body-item-name font-line-1">{{item.appName}}</div>
              <div class="search-ranking-area-body-item-type font-line-1">{{item.standardCategoryName}}  /  V{{item.versionName}}</div>
              <div class="search-ranking-area-body-item-describe font-line-1">这是一个极具挑战性和复杂的应用这是一个极具挑战性和复杂的应用</div>
            </div>
            <div class="search-ranking-area-body-item-btn" @click.stop="openDownload(item)">下载</div>
          </div>
        </div>
        <div class="search-ranking-area-body" v-else>
          <div class="search-ranking-area-empty">
            <div class="search-ranking-area-empty-img">
              <img src="../../static/img/www/search-ranking-area-empty.png">
            </div>
            <div class="search-ranking-area-empty-text">
              暂无搜索结果
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="css-pc search-pagination" v-if="searchConf && searchConf.total>parameter.size">
      <div class="search-pagination-main">
        <van-pagination v-model="parameter.current"
                        :total-items="searchConf.total"
                        :show-page-size="10"
                        :items-per-page="parameter.size"
                        @change="getData()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import commonHead from '../common/commonHead'
import wapCommonHead from '../common/wapCommonHead'
import commonSearchType from '../common/commonSearchType'
import axios from 'axios'
import overallData from '../../../public/js/overall'
export default {
  name: 'index',
  components: {
    commonHead,
    wapCommonHead,
    commonSearchType
  },
  data () {
    return {
      searchState: false,
      value: undefined,
      searchConf: undefined,
      searchList: undefined,
      searchListWap: [],
      currentPage: 1,
      parameter: {
        current: 1,
        size: 20,
        appName: undefined,
        categoryId: '1'
      },
      parameterWap: {
        current: 1,
        size: 20,
        appName: undefined,
        categoryId: '1'
      }
    }
  },
  created () {
    const value = this.$route.query.value
    if (value) {
      this.value = value
      this.getData()
      this.getDataWap()
    } else {
      this.getData()
      this.getDataWap()
    }
  },

  watch: {

  },
  methods: {
    rollbackCommon () {
      history.go(-1)
    },
    searchConfListScroll (e) {
      const scrollTop = e.target.scrollTop // 滚动条当前滚动的距离
      const clientHeight = e.target.clientHeight // 可视区域高度
      const scrollHeight = e.target.scrollHeight // 滚动条整体高度
      const bottomHeight = scrollHeight - scrollTop - clientHeight
      if (bottomHeight <= 100 && this.searchState !== true && this.searchConf && this.searchListWap && this.searchConf.current < this.searchConf.pages) {
        this.getDataWapNext()
      }
    },
    commonHeadSearch (value) {
      this.parameter.current = 1
      this.parameterWap.current = 1
      this.value = value
      this.getData()
      this.getDataWap()
    },
    openDetails (item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    openDownload (item) {
      window.location.href = item.staticApkUrl
    },
    getDataWapNext () {
      this.parameterWap.current = this.parameterWap.current + 1
      this.getDataWap()
    },
    commonSearchTypeChange (type) {
      this.parameter.categoryId = type
      this.parameterWap.categoryId = type
      this.commonHeadSearch(this.value)
    },
    /** 获取数据 */
    getData () {
      this.parameter.appName = this.value
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/search', this.parameter)
        .then((response) => {
          if (response.code === 0) {
            this.searchConf = response.data
            this.searchList = response.data.records
          }
        })
        .catch(function (error) {
          console.log('catch', error)
        })
    },

    /** 获取数据wap */
    getDataWap () {
      this.parameterWap.appName = this.value
      this.searchState = true
      axios.post(overallData.axiosUrl + '/content/v6AppInfoApi/search', this.parameterWap)
        .then((response) => {
          this.searchState = false
          if (this.parameterWap.current === 1) {
            this.searchListWap = []
          }
          if (response.code === 0) {
            this.searchConf = response.data
            const records = response.data.records ? response.data.records : []
            records.forEach((item) => {
              this.searchListWap.push(item)
            })
          }
        })
        .catch(function (error) {
          console.log('catch', error)
        })
    }
  }
}
</script>
<style lang="scss">
.search-pagination{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .search-pagination-main{
    width: 300px;
  }
}
</style>
<style scoped lang="scss">
/*www*/
@media screen and (min-width: 600px) {
  .search-ranking {
    display: flex;
    justify-content: center;

    .search-ranking-area {
      flex-shrink: 0;
      width: 1400px;
      background: #FFFFFF;
      margin-top: 20px;

      .search-ranking-area-head {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 67px;
        border-bottom: 1px solid #EBEDF0;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;

        > span {
          color: #488BFF;
        }
        .search-ranking-area-head-type{
          margin-left: 340px;
        }

        .search-ranking-area-head-sign {
          position: absolute;
          left: 30px;
          bottom: 0px;
          width: 42px;
          height: 4px;
          background: #488BFF;
        }
      }

      .search-ranking-area-body {
        display: flex;
        flex-wrap: wrap;

        .search-ranking-area-body-item {
          cursor: pointer;
          padding: 0px 20px;
          box-sizing: border-box;
          width: 25%;
          height: 92px;
          display: flex;
          align-items: center;

          .search-ranking-area-body-item-icon {
            flex-shrink: 0;
            width: 72px;
            height: 72px;
            border-radius: 18px;
          }

          .search-ranking-area-body-item-name {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .search-ranking-area-body-item-btn {
            display: none;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 61px;
            height: 30px;
            background: #488BFF;
            border-radius: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        .search-ranking-area-body-item:hover {
          background: #F5F9FF;

          .search-ranking-area-body-item-btn {
            display: flex;
          }
        }
      }
      .search-ranking-area-empty{
        width: 100%;
        padding: 300px 0;
        .search-ranking-area-empty-img{
          display: flex;
          justify-content: center;
          >img{
            width: 171px;
            height: 144px;
          }
        }
        .search-ranking-area-empty-text{
          margin-top: 40px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }

  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .search{
    max-height: 100vh;
    overflow: auto;
  }
  .search-ranking {
    .search-ranking-area {
      min-height: 46.875rem;
      background: #FFFFFF;
      .search-ranking-area-head {
        padding: 0.9375rem;
        padding-bottom: 0;
        font-size: 0.8125rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        > span {
          color: #488BFF;
        }
      }

      .search-ranking-area-body {

        .search-ranking-area-body-item {
          padding: 0.875rem 0.9375rem;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .search-ranking-area-body-item-icon {
            flex-shrink: 0;
            width: 3.9375rem;
            height: 3.9375rem;
            border-radius: 0.875rem;
          }
          .search-ranking-area-body-item-detail{
            box-sizing: border-box;
            width: 14.0625rem;
            padding: 0 0.625rem;
            .search-ranking-area-body-item-name{
              font-size: 0.9375rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }
            .search-ranking-area-body-item-type{
              font-size: 0.8125rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
            .search-ranking-area-body-item-describe{
              font-size: 0.8125rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
          }

          .search-ranking-area-body-item-btn {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3.5rem;
            height: 1.75rem;
            background: #488BFF;
            border-radius: 0.875rem;
            font-size: 0.875rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }

  }
  .search-ranking-area-empty{
    padding: 10.9375rem 0;
    .search-ranking-area-empty-img{
      display: flex;
      justify-content: center;
    }
    .search-ranking-area-empty-text{
      margin-top: 2.5rem;
      text-align: center;
      font-size: 0.8125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }

}
</style>
