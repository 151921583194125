import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vuex_speedState: {},
    dict_speed_model_type: [],
    user_feedback_handle_status: []
  },
  mutations: {
    setSpeedState (state, data = {}) {
      state.vuex_speedState = data
    },
    setSpeedModelType (state, data) {
      state.dict_speed_model_type = data
    },
    setUserFeedbackHandleStatus (state, data) {
      state.user_feedback_handle_status = data
    }
  },
  actions: {
  },
  modules: {
  }
})
