<template>
  <div>
    <commonHead @search="commonHeadSearch"></commonHead>
    <wapCommonHead @rollback="rollbackCommon"></wapCommonHead>
    <div class="abnormal404-main">
      <img src="../../static/img/404.png">
    </div>
  </div>
</template>

<script>
import commonHead from '../common/commonHead'
import wapCommonHead from '../common/wapCommonHead'
export default {
  name: '404',
  components: {
    commonHead,
    wapCommonHead
  },
  methods: {
    commonHeadSearch (value) {
      if (!value) {
        this.$router.push({
          path: '/search'
        })
      }
    },
    rollbackCommon () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style scoped lang="scss">
/*www*/
@media screen and (min-width: 600px) {
  .abnormal404-main{
    padding-top: 30vh;
    display: flex;
    justify-content: center;
  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .abnormal404-main{
    padding-top: 25vh;
    display: flex;
    justify-content: center;
  }
}
</style>
