<template>
  <div>
    <commonHead @search="commonHeadSearch"></commonHead>
    <wapCommonHead @rollback="rollbackCommon"></wapCommonHead>
    <div class="css-wap common-navigation">
      <div class="common-navigation-item" @click="openHome">
        首页
      </div>
      <img class="common-navigation-interval" src="../../static/img/wap/common-navigation-interval.png">
      <div class="common-navigation-item">
        应用详情
      </div>
    </div>
    <div class="details">
      <div class="details-main">
        <div class="css-pc details-main-exhibition">
          <div class="details-main-exhibition-detail">
            <div class="details-main-exhibition-detail-icon"><img :src="appDetail.staticIconUrl"></div>
            <div class="details-main-exhibition-detail-auxiliary">
              <div class="details-main-exhibition-detail-auxiliary-name">{{ appDetail.appName }}</div>
              <div class="details-main-exhibition-detail-auxiliary-download" @click="openDownload(appDetail)">立即下载</div>
            </div>
          </div>
          <div class="details-main-exhibition-code">
            <div class="details-main-code">
              <div ref="qrCode"></div>
            </div>
            <div class="details-main-explain">扫描二维码即可下载</div>
          </div>
        </div>
        <div class="css-wap">
          <div class="details-main-icon">
            <img :src="appDetail.staticIconUrl">
          </div>
          <div class="details-main-name">
            {{ appDetail.appName }}
          </div>
        </div>
        <div class="details-main-message">
          <div class="details-main-message-list">
            <div class="details-main-message-list-item">
              <div>应用分类：</div>
              <div>{{ appDetail.standardCategoryName }}</div>
            </div>
            <div class="details-main-message-list-item">
              <div>语言：</div>
              <div>{{ appDetail.language }}</div>
            </div>
            <div class="details-main-message-list-item">
              <div>应用版本：</div>
              <div>V{{ appDetail.versionName }}</div>
            </div>
            <div class="details-main-message-list-item">
              <div>应用大小：</div>
              <div>{{ appDetail.apkSize | sizeName }}</div>
            </div>
            <div class="details-main-message-list-item">
              <div>发布时间：</div>
              <div>{{ releaseTimevisual(appDetail.releaseTimeMillis) }}</div>
            </div>
          </div>
        </div>
        <!-- 温馨提示 -->
        <div class="details-main-setForth" v-if="appDetail.reminder">
          <div class="details-main-setForth-title">温馨提示</div>
          <div class="details-main-setForth-content" :class="moreConfPc.reminder.state?'showMore':''"><pre v-html="appDetail.reminder" ref="reminderContent"></pre></div>
          <div class="details-main-setForth-more" v-if="moreConfPc.reminder.possess">
            <div class="details-main-setForth-more-btn" v-if="moreConfPc.reminder.state" @click="moreConfPc.reminder.state = !moreConfPc.reminder.state">
              <img src="../../static/img/www/details-main-setForth-more.png">
              展开
            </div>
            <div class="details-main-setForth-more-btn" v-else @click="moreConfPc.reminder.state = !moreConfPc.reminder.state">
              <img class="more-top" src="../../static/img/www/details-main-setForth-more.png">
              折叠
            </div>
          </div>
        </div>
        <!-- 游戏截图 -->
        <div class="details-main-screenshot" v-if="appDetail.staticScreenshotList && appDetail.staticScreenshotList.length>0">
          <div class="details-main-screenshot-title">游戏截图</div>
          <div class="details-main-screenshot-list">
            <swiper class="speedSwiper" ref="speedSwiper" :options="mySwiperOption">
              <swiper-slide v-for="(item,index) in appDetail.staticScreenshotList" :key="index">
                <div class="screenshot-list-item">
                  <img class="screenshot-list-item-img" :src="item" @click.stop="openImgShow(item)">
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div><!--分页器。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
        </div>
        <!-- 更新说明 -->
        <div class="details-main-setForth" v-if="appDetail.updatesLog">
          <div class="details-main-setForth-title">更新说明</div>
          <div class="details-main-setForth-content" :class="moreConfPc.updatesLog.state?'showMore':''"><pre v-html="appDetail.updatesLog" ref="updatesLogContent"></pre></div>
          <div class="details-main-setForth-more" v-if="moreConfPc.updatesLog.possess">
            <div class="details-main-setForth-more-btn" v-if="moreConfPc.updatesLog.state" @click="moreConfPc.updatesLog.state = !moreConfPc.updatesLog.state">
              <img src="../../static/img/www/details-main-setForth-more.png">
              展开
            </div>
            <div class="details-main-setForth-more-btn" v-else @click="moreConfPc.updatesLog.state = !moreConfPc.updatesLog.state">
              <img class="more-top" src="../../static/img/www/details-main-setForth-more.png">
              折叠
            </div>
          </div>
        </div>
        <!-- 游戏介绍 -->
        <div class="details-main-setForth" v-if="appDetail.description">
          <div class="details-main-setForth-title">游戏介绍</div>
          <div class="details-main-setForth-content" :class="moreConfPc.description.state?'showMore':''"><pre v-html="appDetail.description" ref="descriptionContent"></pre></div>
          <div class="details-main-setForth-more" v-if="moreConfPc.description.possess">
            <div class="details-main-setForth-more-btn" v-if="moreConfPc.description.state" @click="moreConfPc.description.state = !moreConfPc.description.state">
              <img src="../../static/img/www/details-main-setForth-more.png">
              展开
            </div>
            <div class="details-main-setForth-more-btn" v-else @click="moreConfPc.description.state = !moreConfPc.description.state">
              <img class="more-top" src="../../static/img/www/details-main-setForth-more.png">
              折叠
            </div>
          </div>
        </div>
        <div class="css-wap details-main-btn">
          <div class="details-main-btn-download" @click="openDownload(appDetail)">
            立即下载
          </div>
        </div>
      </div>
    </div>
    <div class="details-imgShow" v-show="imgShowConf.show" @click.stop="imgShowConf.show=false">
      <img :src="imgShowConf.url">
    </div>
  </div>

</template>

<script>
import commonHead from '../common/commonHead'
import wapCommonHead from '../common/wapCommonHead'
import axios from 'axios'
import overallData from '../../../public/js/overall'
import QRCode from 'qrcodejs2'
export default {
  name: 'index',
  components: {
    commonHead,
    wapCommonHead
  },
  data () {
    return {
      id: undefined,
      tid: undefined,
      imgShowConf: {
        show: false,
        url: undefined
      },
      appDetail: {},
      moreConfPc: {
        reminder: {
          possess: false, // 是否拥有
          state: false // true折叠省略 false全部展示
        },
        updatesLog: {
          possess: false, // 是否拥有
          state: false // true折叠省略 false全部展示
        },
        description: {
          possess: false, // 是否拥有
          state: false // true折叠省略 false全部展示
        }
      },
      mySwiperOption: {
        freeMode: true,
        momentum: false,
        slidesPerView: 'auto',
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        }
      }
    }
  },
  filters: {
    sizeName (size) {
      const s = size / 1024
      if (s < 1024) {
        return s + 'K'
      } else if (s < 1024 * 1024) {
        return (s / 1024).toFixed(2) + 'M'
      } else if (s < 1024 * 1024 * 1024) {
        return (s / 1024 / 1024).toFixed(2) + 'G'
      }
      return s
    }

  },
  created () {
    const id = this.$route.query.id
    const tid = this.$route.query.tid
    if (id) {
      this.id = id
      this.getData()
    } else {
      this.tid = tid
      this.getData()
    }
  },
  methods: {
    releaseTimevisual (timesStamp) {
      if (!timesStamp) {
        return '--'
      }
      const time = new Date(timesStamp)
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const date = time.getDate()
      const hour = time.getHours()
      const min = time.getMinutes()
      const sec = time.getSeconds()

      return year + '-' + this.strInit(month) + '-' + this.strInit(date) + ' ' + this.strInit(hour) + ':' + this.strInit(min) + ':' + this.strInit(sec)
    },
    /** 小于10补0 */
    strInit (str) {
      if (str < 10) {
        return '0' + str
      }
      return str
    },
    commonHeadSearch (value) {
      if (!value) {
        this.$router.push({
          path: '/search'
        })
      }
    },
    rollbackCommon () {
      history.go(-1)
    },
    openImgShow (item) {
      this.imgShowConf.url = item
      this.imgShowConf.show = true
    },
    /** 生成页面二维码图案 */
    createQRCode () {
      /* eslint-disable no-new */
      new QRCode(this.$refs.qrCode, {
        text: this.appDetail.staticApkUrl,
        width: 110,
        height: 110,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      })
    },
    openHome () {
      this.$router.push({
        path: '/'
      })
    },
    openDownload (item) {
      window.location.href = item.staticApkUrl
    },
    /** 获取数据 */
    getData () {
      let apiUrl = '/content/v6AppInfoApi/getAppDetail4V6'
      const parameter = {}
      if (this.id) {
        parameter.id = this.id
      } else {
        apiUrl = '/content/v6AppInfoApi/getAppDetail4t'
        parameter.tid = this.tid
      }
      axios.post(overallData.axiosUrl + apiUrl, parameter)
        .then((response) => {
          if (response.code === 0) {
            if (response.data && response.data.appName) {
              this.appDetail = response.data
              this.createQRCode()
              this.$nextTick(() => {
                // 获取视口宽度
                // const viewportWidth = window.innerWidth
                // 获取整个窗口的宽度
                const windowWidth = window.outerWidth
                const terminalType = windowWidth * 1 > 600 ? 'pc' : 'wap'
                let fontSize = 25 // pc尺寸
                if (terminalType === 'wap') {
                  fontSize = window.getComputedStyle(document.documentElement).fontSize.replace('px', '') * 1
                }
                const reminderContent = this.$refs.reminderContent
                const updatesLogContent = this.$refs.updatesLogContent
                const descriptionContent = this.$refs.descriptionContent
                if (reminderContent && reminderContent.offsetHeight > fontSize * 5) {
                  this.moreConfPc.reminder.possess = true
                  this.moreConfPc.reminder.state = true
                }
                if (updatesLogContent && updatesLogContent.offsetHeight > fontSize * 5) {
                  this.moreConfPc.updatesLog.possess = true
                  this.moreConfPc.updatesLog.state = true
                }
                if (descriptionContent && descriptionContent.offsetHeight > fontSize * 5) {
                  this.moreConfPc.description.possess = true
                  this.moreConfPc.description.state = true
                }
              })
            } else {
              this.$router.push({
                path: '/404'
              })
            }
          }
        })
        .catch(function (error) {
          console.log('catch', error)
        })
    }
  }
}
</script>

<style lang="scss">
/*www*/
@media screen and (min-width: 600px){
  .details-main-screenshot{
    .swiper-wrapper{
      position: relative;
      .swiper-slide{
        width: auto;
      }
    }
    .swiper-pagination{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      .swiper-pagination-bullet{
        margin: 0 2px;
        padding: 0px 4px;
        border-radius: 4px;
      }
    }
    .swiper-button-prev{
      z-index: 100;
      background-image: url("../../static/img/www/swiper-button-prev.png");
    }
    .swiper-button-next{
      z-index: 100;
      background-image: url("../../static/img/www/swiper-button-next.png");
    }
  }

}
/*wap*/
@media screen and (max-width: 600px) {
  .details-main-screenshot{
    .swiper-wrapper{
      position: relative;
      .swiper-slide{
        width: auto;
      }
    }
    .swiper-pagination{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.5rem;
      .swiper-pagination-bullet{
        height: 0.25rem;
        margin: 0 0.125rem;
        padding: 0px 0.2rem;
        border-radius: 0.1875rem;
      }
    }
    .swiper-button-prev{
      z-index: 100;
      background-image: url("../../static/img/www/swiper-button-prev.png");
    }
    .swiper-button-next{
      z-index: 100;
      background-image: url("../../static/img/www/swiper-button-next.png");
    }
  }
}
</style>
<style scoped lang="scss">
/*www*/
@media screen and (min-width: 600px) {
  .details {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .details-main {
      width: 1400px;
      padding-top: 34px;
      padding-bottom: 64px;
      background: #FFFFFF;
      .details-main-exhibition{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 73px;
        .details-main-exhibition-detail{
          display: flex;
          align-items: center;
          .details-main-exhibition-detail-icon{
            width: 133px;
            height: 133px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 24px;
            }
          }
          .details-main-exhibition-detail-auxiliary{
            margin-left: 24px;
            .details-main-exhibition-detail-auxiliary-name{
              max-width: 900px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              font-size: 28px;
              color: #000000;
              margin-bottom: 10px;
            }
            .details-main-exhibition-detail-auxiliary-download{
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 260px;
              height: 57px;
              background: #488BFF;
              border-radius: 28px;
              font-weight: bold;
              font-size: 20px;
              color: #FFFFFF;
            }
          }
        }
        .details-main-exhibition-code{
          .details-main-code {
            display: flex;
            justify-content: center;
            margin-top: 6px;
            > img {
              width: 130px;
              height: 130px;
            }
          }
          .details-main-explain {
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      .details-main-icon {
        display: flex;
        justify-content: center;

        > img {
          width: 105px;
          height: 104px;
          border-radius: 24px;
        }
      }

      .details-main-name {
        margin-top: 26px;
        text-align: center;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .details-main-setForth{
        padding: 0 73px;
        margin-top: 30px;

        .details-main-setForth-title{
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          margin-bottom: 10px;
        }
        .details-main-setForth-content{
          font-weight: 400;
          font-size: 15px;
          color: #666666;
          line-height: 25px;
          pre{
            margin: 0;
          }
          *{
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
          }

        }
        .details-main-setForth-content.showMore{
          overflow: hidden; /* 文本超出的部分隐藏 */
          display: -webkit-box;/* 弹性伸缩盒模型显示 */
          -webkit-line-clamp: 5;/* 限制在一个块元素中显示文本的行数 */
          -webkit-box-orient: vertical;/* 设置伸缩盒子对象的子元素的排列方式 */
        }
        .details-main-setForth-more{
          margin-top: 10px;
          display: flex;
          justify-content: center;
          .details-main-setForth-more-btn{
            user-select: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 26px;
            background: #ECF3FF;
            border-radius: 13px;
            font-weight: 400;
            font-size: 13px;
            color: #488BFF;
            >img{
              margin-right: 3px;
            }
            .more-top{
              transform: rotate(180deg);
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari and Chrome */
            }
          }
        }
      }

      .details-main-message {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        .details-main-message-list {
          width: 1254px;
          background: #F5F5F5;
          border-radius: 20px;
          padding: 28px 0;
          padding-bottom: 8px;

          .details-main-message-list-item {
            display: flex;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            margin-bottom: 20px;

            > div:nth-child(1) {
              text-align: right;
              width: 120px;
              margin-right: 10px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }

      .details-main-btn {
        display: flex;
        justify-content: center;
        margin-top: 70px;

        .details-main-btn-download {
          z-index:100;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 359px;
          height: 57px;
          background: #488BFF;
          border-radius: 28px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .details-main-screenshot{
        padding: 0 73px;
        margin-top: 30px;
        .details-main-screenshot-title{
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          margin-bottom: 10px;
        }
        .details-main-screenshot-list{
          position: relative;
          .screenshot-list-item{
            width: auto;
            padding-bottom: 10px;
            .screenshot-list-item-img{
              background: rgba(0,0,0,0.03);
              width: auto;
              height: 300px;
            }
          }
        }
      }

    }

  }
  .details-imgShow{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
      width: auto;
      height:auto;
      max-width: 90%;
      max-height: 90%;
    }
  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .common-navigation{
    height: 1.875rem;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EEEEEE;
    background: #ffffff;
    .common-navigation-interval{
      height: 100%;
    }
    .common-navigation-item{
      display: flex;
      align-items: center;
      padding: 0px 1.0625rem;
      height: 100%;
    }
  }
  .details {
    .details-main {
      padding: 2.5rem 0;
      background: #FFFFFF;
      .details-main-icon {
        display: flex;
        justify-content: center;
        > img {
          width: 5.1875rem;
          height: 5.1875rem;
          border-radius: 1rem;
        }
      }
      .details-main-name {
        margin-top: 1.25rem;
        text-align: center;
        font-size: 1.125rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .details-main-message {
        display: flex;
        justify-content: center;
        margin-top: 1.75rem;
        .details-main-message-list {
          width: 21.125rem;
          background: #F5F5F5;
          border-radius: 1.0625rem;
          padding: 1.4375rem 0;
          padding-bottom: 0.5rem;
          .details-main-message-list-item {
            display: flex;
            font-size: 0.9375rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            margin-bottom: 1.25rem;
            > div:nth-child(1) {
              text-align: right;
              width: 6.25rem;
              margin-right: 0.625rem;
              font-size: 0.9375rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }

      .details-main-setForth{
        margin-top: 0.9375rem;
        padding: 0 1.125rem;
        .details-main-setForth-title{
          margin-bottom: 0.7rem;
          font-weight: 700;
          font-size: 1rem;
          color: #333333;
        }
        .details-main-setForth-content{
          font-weight: 400;
          font-size: 0.875rem;
          color: #666666;
          line-height: 1.375rem;
          pre{
            margin: 0;
          }
          *{
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
          }
        }
        .details-main-setForth-content.showMore{
          overflow: hidden; /* 文本超出的部分隐藏 */
          display: -webkit-box;/* 弹性伸缩盒模型显示 */
          -webkit-line-clamp: 5;/* 限制在一个块元素中显示文本的行数 */
          -webkit-box-orient: vertical;/* 设置伸缩盒子对象的子元素的排列方式 */
        }
        .details-main-setForth-more{
          margin-top: 0.625rem;
          display: flex;
          justify-content: center;
          .details-main-setForth-more-btn{
            user-select: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4.375rem;
            height: 1.625rem;
            background: #ECF3FF;
            border-radius: 0.8125rem;
            font-weight: 400;
            font-size: 0.8125rem;
            color: #488BFF;
            >img{
              margin-right: 0.1rem;
            }
            .more-top{
              transform: rotate(180deg);
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari and Chrome */
            }
          }
        }
      }

      .details-main-btn {
        position: sticky;
        z-index:800;
        left: 0;
        right: 0;
        bottom: 1.5rem;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        .details-main-btn-download {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17.875rem;
          height: 2.8125rem;
          background: #488BFF;
          border-radius: 1.4375rem;
          font-size: 1rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .details-main-explain {
        text-align: center;
        margin-top: 2.125rem;
        font-size: 0.8125rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      .details-main-code {
        display: flex;
        justify-content: center;
        margin-top: 0.375rem;

        > img {
          width: 8.125rem;
          height: 8.125rem;
        }
      }
      .details-main-screenshot{
        margin-top: 0.9375rem;
        padding: 0 1.125rem;
        .details-main-screenshot-title{
          margin-bottom: 0.7rem;
          font-weight: 700;
          font-size: 1rem;
          color: #333333;
        }
        .details-main-screenshot-list{
          position: relative;
          .screenshot-list-item{
            width: auto;
            padding-bottom: 0.625rem;
            .screenshot-list-item-img{
              background: rgba(0,0,0,0.03);
              width: auto;
              height: 12.5rem;
            }
          }
        }
      }
    }
  }
  .details-imgShow{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
      width: auto;
      height:auto;
      max-width: 90%;
      max-height: 90%;
    }
  }
}
</style>
