const formatCheck = {
  // 手机号码格式
  mobileNumber (number) {
    if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(number))) {
      return false
    }
    return true
  }
}

export default formatCheck
