<template>
  <div id="app">
      <router-view/>
  </div>
</template>
<script>

export default {
  created () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
html,body,img{
    padding: 0;
    margin: 0;
}
*{
  font-family: Microsoft YaHei;
}
img {
  border: none;
  padding: 0;
  margin: 0;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */ image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

}
/*单行文字溢出隐藏*/
.font-line-1{
  white-space: nowrap;      /*超出的空白区域不换行*/
  overflow: hidden;         /*超出隐藏*/
  text-overflow: ellipsis;  /*文本超出显示省略号*/
}
/*节点不可复制*/
.non-copyable{
  -moz-user-select:none; /* Firefox私有属性 */
  -webkit-user-select:none; /* WebKit内核私有属性 */
  -ms-user-select:none; /* IE私有属性(IE10及以后) */
  -khtml-user-select:none; /* Khtml内核私有属性 */
  -o-user-select:none; /* Opera私有属性 */
  user-select:none; /* CSS3属性 */
}
/*滚动条宽 长,滚动条整体部分，其中的属性有width,height,background,border等。*/
.roll-beautify::-webkit-scrollbar{
  width: 7px;
}

/*滚动条的滑轨背景颜色,可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果。*/
.roll-beautify::-webkit-scrollbar-track{
  background-color: rgba(0,0,0,.01);
  -webkit-box-shadow:inset 0 0 3px rgba(0,0,0,0.1);
  border-radius:5px;
}
/* 滑块颜色 */
.roll-beautify::-webkit-scrollbar-thumb{
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 5px;
}
/*滚动条两端的按钮。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果。*/
.roll-beautify::-webkit-scrollbar-button{
  background-color: #eee;
  display: none;
}
/* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.roll-beautify::-webkit-scrollbar-corner{
  background-color: black;
}
/*www*/
@media screen and (min-width: 600px) {
  .css-wap {
    display: none !important;
  }
  body{
    min-width: 1450px;
  }
}
/*wap*/
@media screen and (max-width: 600px) {
  .css-pc{
    display: none !important;
  }
}
</style>
